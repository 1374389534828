import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1;
const COLLECTION_NAME = "NS_NASDAQ_LINES_DATE_LIST";

interface IXUData {
  linesDates: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  linesDates: [],
  requested: false,
  loaded: false,
};

export function loadLinesDates(firebase: any, bars: number = 0) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.LinesDates.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.NS_LINES_DATE_LIST,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          // linesDatesData = doc.data().dateList;
          var items = doc.data().dateList;
          data.push({
            UpdateTime: doc.data().dateTime.seconds,
            dates: items
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.NS_LINES_DATE_LIST,
          payload: {
            linesDates: data.slice(-bars),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function LinesDates(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NS_LINES_DATE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesDatesSelector = (state: any) => state.LinesDates;
