import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nsUserSelector, refreshUser, loadNsUser } from "../appRedux/reducers/sentiment/user";

import asyncComponent from "util/asyncComponent";
import { Modal } from "antd";
import { Avatar, Checkbox, Tag, Tooltip } from "antd";
import { userApi } from "../api/user/user-api";
import { LoadingWidget } from "../components/LoadingWidget/LoadingWidget";
import { firebaseApp } from "../firebase"
import { useAuth } from "../authentication";
import { useAppDispatch } from "../NextApp"
const App = ({ match }) => {
  const [agreed, setAgreed] = useState(false);
  const [waitingAgreement, setWaitingAgreement] = useState(false);
  const { authUser } = useAuth();

  const dispatch = useAppDispatch();
  const nsUser = useSelector(nsUserSelector);
  if (false && nsUser?.loaded && !nsUser?.agreement)
    return (
      <Modal
        title="Üyelik Sözleşmesi Onayı"
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Devam et"
        okButtonProps={{
          style: {
            cursor: !agreed ? "not-allowed" : "pointer",
          },
        }}
        onOk={() => {
          if (!agreed) return false;
          setWaitingAgreement(true);
          dispatch(userApi.endpoints.agreement.initiate())
            .unwrap()
            .finally(() => {
              dispatch(refreshUser()).then(() => {
                dispatch(loadNsUser(firebaseApp, authUser.uid));

              });;
              setWaitingAgreement(false);
            });
        }}
        open={true}
      >
        {waitingAgreement ? (
          <LoadingWidget />
        ) : (
          <div className="gx-modal-box-row">
            <div className="agreement-content">
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/algo-senti.appspot.com/o/ns%2Flegal%2FSentiment%20Algo%20Uyelik%20Sozlesmesi.pdf?alt=media&token=73e3b103-6fd3-417c-9e6a-0f91dc6e6516"
              >
                Üyelik Sözleşmesini Görüntüle
              </a>
            </div>
            <div>
              <Checkbox onChange={(e) => setAgreed(e.target.checked)}>
                {" "}
                Üyelik sözleşmesini okudum, kabul ediyorum.
              </Checkbox>
            </div>
          </div>
        )}
      </Modal>
    );

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}nasdaq`}
          component={asyncComponent(() => import("./Nasdaq"))}
        />
        <Route
          path={`${match.url}nifty50`}
          component={asyncComponent(() => import("./Nifty50"))}
        />
        <Route
          path={`${match.url}sp500`}
          component={asyncComponent(() => import("./SP500"))}
        />
        <Route
          path={`${match.url}fx`}
          component={asyncComponent(() => import("./FX"))}
        />
        <Route
          path={`${match.url}help`}
          component={asyncComponent(() => import("./Help"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
        <Route
          path={`${match.url}notifications`}
          component={asyncComponent(() => import("./Notifications"))}
        />
        <Route
          path={`${match.url}changelog`}
          component={asyncComponent(() => import("./ChangeLog"))}
        />
      </Switch>
    </div>
  );
};

export default App;
