import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 50;
const COLLECTION_NAME = "NS_NASDAQ_SIGNALS_400";

interface IXUData {
  signal400: { Basket: string[]; Outgoing: []; Incoming: []; Index: number };
  requested: boolean;
  loaded: boolean;
  version: string;
}

const initialState: IXUData = {
  signal400: {} as any,
  requested: false,
  loaded: false,
  version: ""
};

export function loadSignal400(firebase: any, period: number, version: string = "") {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for signal 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }

  return async function thunk(dispatch: any, getState: any) {
    let collectionWithVersion = COLLECTION_NAME;
    if (version != null && version != "")
      collectionWithVersion = `${collectionWithVersion}_${version}`;

    const state = getState();
    if (state.Signal400.requested && state.Signal400.version == version) return;
    dispatch({
      type: SENTIMENT_ACTION.SIGNAL_400,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    let ix = 0;
    firebase
      .firestore()
      .collection(`${collectionWithVersion}_P${period}`)
      .where("reverse", "==", 0)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let signal400Data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          signal400Data.push({
            key: ix++,
            name: "signal",
            Basket: doc.data().basket ?? [],
            Outgoing: doc.data().outgoing ?? [],
            Index: doc.data().index,
            Incoming: doc.data().incoming ?? [],
            Time: doc.data().time ?? 0,
            Date: moment(doc.data().dateTime.toDate())
              //.zone(0)
              .format("DD.MM.YY HH:mm"),
          });
        });
        dispatch({
          type: SENTIMENT_ACTION.SIGNAL_400,
          payload: {
            signal400: signal400Data,
            loaded: true,
            requested: true,
            version: version
          },
        });
      });
  };
}

export default function Signal400(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.SIGNAL_400:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const signal400Selector = (state: any) => state.Signal400;
