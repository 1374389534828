import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "../Params";

const DATA_COUNT = 28;
const COLLECTION_NAME = "NS_FX_LINES_30";

interface IXUData {
  lines30: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
  bars: number;
}

const initialState: IXUData = {
  lines30: [],
  requested: false,
  loaded: false,
  lastUpdate: 0,
  bars: 0
};

export function loadFXLines30Snapshot(firebase: any, period: number, bars: number = 0) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime() - state.FXLines30Snapshot.lastUpdate < 60 * 1000 && state.bars == bars) return;

    dispatch({
      type: SENTIMENT_ACTION.FX_LINES_30,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .get().then((querySnapshot: any) => {
        var lines30Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          //if (lines30Data.some((x: any) => { x.Symbol == doc.data().symbol })) return;

          lines30Data.push({
            name: "lines30",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : "",
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList.slice(-bars),
            PriceList: doc.data().priceList.slice(-bars),
            ChangeList: doc.data().changeList.slice(-bars),
            ReChangeList: doc.data().changeList.slice(-bars).reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            VWMA: doc.data().vwma?.reverse()??[],
            SentiPer : ((doc.data().sentimentList[doc.data().sentimentList.length - 1] - doc.data().sentimentList[doc.data().sentimentList.length - 2]) / doc.data().sentimentList[doc.data().sentimentList.length - 2] * 100).toFixed(2),
          });
        });

        let t = lines30Data.sort((a: any, b: any) => { return a.Order - b.Order });

        dispatch({
          type: SENTIMENT_ACTION.FX_LINES_30,
          payload: {
            lines30: lines30Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime()
          },
        });
      });
  };
}

export default function FXLines30Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.FX_LINES_30:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const FXlines30SnapshotSelector = (state: any) => state.FXLines30Snapshot;
