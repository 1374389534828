import moment from "moment";
import { IData } from "../web/common";
import sectorAndIndustries from "../../../util/sectorAndIndustries";
import { SENTIMENT_ACTION } from "../sentiment/actions";

const COLLECTION_NAME = "NS_NIFTY_LINES_50";

interface IXUData {
  lines50: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  lines50: [],
  requested: false,
  loaded: false,
};

export function loadNiftyLines50(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 50, 99].includes(period)) {
    console.log("Invalid period for line 50. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.NiftyLines50.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.NIFTY_LINES_50,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}_P${period}`)
      .orderBy("dateTime", "desc")
      .onSnapshot((querySnapshot: any) => {
        var lines50Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          let tickerInfo = sectorAndIndustries.filter((x: any) => { return x.Symbol == doc.data().symbol })[0] || { Company: "", Sector: "", Industry: "" };
          lines50Data.push({
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : "",
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            VWMA: doc.data().vwma?.reverse(),
            SentiPer: ((doc.data().sentimentList[doc.data().sentimentList.length - 1] - doc.data().sentimentList[doc.data().sentimentList.length - 2])
              / doc.data().sentimentList[doc.data().sentimentList.length - 2] * 100).toFixed(2),
            Company: tickerInfo.Company,
            Sector: tickerInfo.Sector,
            Industry: tickerInfo.Industry,
          });
        });
        
        dispatch({
          type: SENTIMENT_ACTION.NIFTY_LINES_50,
          payload: {
            lines50: lines50Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function NiftyLines50(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NIFTY_LINES_50:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const niftylines50Selector = (state: any) => state.NiftyLines50;
