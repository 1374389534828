import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 12 * 100;
const COLLECTION_NAME = "NS_NASDAQ_TRADES_200";

interface IXUData {
  trade200: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  trade200: [],
  requested: false,
  loaded: false,
};

export function loadTrade200(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Trade200.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.TRADE_200,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var trade200Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var opendt = doc.data().openDate.split(".");

          trade200Data.push({
            uid: doc.data().symbol + '_' + doc.data().openDate,
            name: "trade200",
            Index: doc.data().index,
            DataPackage: doc.data().dataPackage,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            Symbol: doc.data().symbol,
            ODate: moment(doc.data().openDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm"),
            CDate: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm") : "",
            OPrice: doc.data().openPrice,
            CPrice: doc.data().closePrice,
            PerDay: doc.data().perDay,
            Percent: doc.data().percent,
            Duration: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY").diff(moment(doc.data().openDate, "DD.MM.YYYY"), 'days') : 0,
            dateTime: doc.data().dateTime.toDate(),
            dateOpen: new Date(opendt[2], opendt[1] - 1, opendt[0]),
            STP: doc.data().stpPositive
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.TRADE_200,
          payload: {
            trade200: trade200Data.sort((a: any, b: any) => { return b.dateTime - a.dateTime || a.dateOpen - b.dateOpen }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Trade200(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TRADE_200:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const trade200Selector = (state: any) => state.Trade200;
