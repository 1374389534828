import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 365;
const COLLECTION_NAME = "NS_NASDAQ_BALANCES";

interface IBalanceData {
  balance: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IBalanceData = {
  balance: [],
  requested: false,
  loaded: false,
};

export function loadDailyBalances(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailyBalances.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.DAILY_BALANCES,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var balanceData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const date = moment
            .unix(doc.data().dateTime.seconds)
            .format("DD.MM.YYYY");
          balanceData.push({
            date: date,
            ndx: doc.data().ndx,
            xNdx030: doc.data().xNdx030,
            xNdx070: doc.data().xNdx070,
          });
        });

        balanceData = balanceData.reverse();
        dispatch({
          type: SENTIMENT_ACTION.DAILY_BALANCES,
          payload: {
            balance: balanceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailyBalances(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.DAILY_BALANCES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailyBalancesSelector = (state: any) =>
  state.DailyBalances;
