const spxSectors=[
{symbol:"OABI",sic:"IndustrialApp&Services"},
{symbol:"CHE",sic:"IndustrialApp&Services"},
{symbol:"NFLX",sic:"Trade&Services"},
{symbol:"ACN",sic:"Trade&Services"},
{symbol:"EBAY",sic:"Trade&Services"},
{symbol:"PYPL",sic:"Trade&Services"},
{symbol:"UBER",sic:"Trade&Services"},
{symbol:"OTEX",sic:"Technology"},
{symbol:"BBAI",sic:"Technology"},
{symbol:"SOUN",sic:"Technology"},
{symbol:"CRM",sic:"Technology"},
{symbol:"MSFT",sic:"Technology"},
{symbol:"OKTA",sic:"Technology"},
{symbol:"ORCL",sic:"Technology"},
{symbol:"PATH",sic:"Technology"},
{symbol:"PLTR",sic:"Technology"},
{symbol:"SNOW",sic:"Technology"},
{symbol:"APP",sic:"Technology"},
{symbol:"GOOGL",sic:"Technology"},
{symbol:"META",sic:"Technology"},
{symbol:"PINS",sic:"Technology"},
{symbol:"SNAP",sic:"Technology"},
{symbol:"ZM",sic:"Technology"},
{symbol:"AL",sic:"Trade&Services"},
{symbol:"WH",sic:"RealEstate&Construction"},
{symbol:"CCI",sic:"RealEstate&Construction"},
{symbol:"CBRE",sic:"RealEstate&Construction"},
{symbol:"RLI",sic:"Finance"},
{symbol:"NDAQ",sic:"Finance"},
{symbol:"NYCB",sic:"Finance"},
{symbol:"AMZN",sic:"Trade&Services"},
{symbol:"CHWY",sic:"Trade&Services"},
{symbol:"PARA",sic:"Technology"},
{symbol:"DINO",sic:"Energy&Transportation"},
{symbol:"TER",sic:"IndustrialApp&Services"},
{symbol:"RTX",sic:"Manufacturing"},
{symbol:"PCAR",sic:"Manufacturing"},
{symbol:"RIVN",sic:"Manufacturing"},
{symbol:"QS",sic:"Manufacturing"},
{symbol:"AMAT",sic:"Manufacturing"},
{symbol:"AMD",sic:"Manufacturing"},
{symbol:"AVGO",sic:"Manufacturing"},
{symbol:"CRUS",sic:"Manufacturing"},
{symbol:"ENPH",sic:"Manufacturing"},
{symbol:"INTC",sic:"Manufacturing"},
{symbol:"MCHP",sic:"Manufacturing"},
{symbol:"MRVL",sic:"Manufacturing"},
{symbol:"MU",sic:"Manufacturing"},
{symbol:"NVDA",sic:"Manufacturing"},
{symbol:"NXPI",sic:"Manufacturing"},
{symbol:"QRVO",sic:"Manufacturing"},
{symbol:"RMBS",sic:"Manufacturing"},
{symbol:"FLEX",sic:"Manufacturing"},
{symbol:"CIEN",sic:"Manufacturing"},
{symbol:"PANW",sic:"Technology"},
{symbol:"AAPL",sic:"Technology"},
{symbol:"DELL",sic:"Technology"},
{symbol:"SMCI",sic:"Technology"},
{symbol:"IBM",sic:"Technology"},
{symbol:"NKE",sic:"Manufacturing"},
{symbol:"COP",sic:"Energy&Transportation"},
{symbol:"PSX",sic:"Energy&Transportation"},
{symbol:"VLO",sic:"Energy&Transportation"},
{symbol:"XOM",sic:"Energy&Transportation"},
{symbol:"PG",sic:"IndustrialApp&Services"},
{symbol:"DNA",sic:"LifeSciences"},
{symbol:"MRNA",sic:"LifeSciences"},
{symbol:"RXRX",sic:"LifeSciences"},
{symbol:"MRO",sic:"Energy&Transportation"},

];

export default spxSectors;