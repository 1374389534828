export const PERMISSIONS = {
  NASDAQ: "nasdaq.all",
  SENTISPX: "sentiSpx.all",
  FX: "fx.all",
};

export const isCooperateUser = (nsUser: any) => {
  console.log(nsUser)
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.package?.admin) return true;
  return false;
};

export const userHasPermission = (nsUser: any, permission: string) => {
  if (!nsUser || !nsUser?.user) return false;
  return nsUser?.user?.[permission] === true;
};
