import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import Common from "./Common";
import DailySuggestions from "./sentiment/daily-suggestions";

import NiftyLines20 from "./nifty50/lines-20";
import NiftyLines30 from "./nifty50/lines-30";
import NiftyLines50 from "./nifty50/lines-50";

import NiftySignal20 from "./nifty50/signal-20";
import NiftySignal30 from "./nifty50/signal-30";
import NiftySignal50 from "./nifty50/signal-50";

import NiftyTrade20 from "./nifty50/trade-20";
import NiftyTrade30 from "./nifty50/trade-30";
import NiftyTrade50 from "./nifty50/trade-50";

import NiftyLinesDates from "./nifty50/lines-dates";



import Lines30 from "./sentiment/lines-30";
import Lines500 from "./sentiment/lines-500";
import Lines70 from "./sentiment/lines-70";
import Lines200 from "./sentiment/lines-200";
import Lines400 from "./sentiment/lines-400";
import Lines99 from "./sentiment/lines-ETFs";
import News from "./sentiment/news";
import Portfolio from "./sentiment/portfolio";
import Signal30 from "./sentiment/signal-30";
import Signal500 from "./sentiment/signal-500";
import Signal70 from "./sentiment/signal-70";
import Signal200 from "./sentiment/signal-200";
import Signal400 from "./sentiment/signal-400";
import Signal99 from "./sentiment/signal-ETF";
import Trade30 from "./sentiment/trade-30";
import Trade500 from "./sentiment/trade-500";
import Trade70 from "./sentiment/trade-70";
import Trade200 from "./sentiment/trade-200";
import Trade400 from "./sentiment/trade-400";
import NsUser from "./sentiment/user";
import Xsen030DailyBalance from "./sentiment/xsen030-daily-balance";
import Xsen030MonthlyBalance from "./sentiment/xsen030-monthly-balance";
import Xsen070DailyBalance from "./sentiment/xsen070-daily-balance";
import Xsen070MonthlyBalance from "./sentiment/xsen070-monthly-balance";
import Xsen500DailyBalance from "./sentiment/xsen500-daily-balance";
import Xsen500MonthlyBalance from "./sentiment/xsen500-monthly-balance";
import XU30Daily from "./sentiment/xu030";
import XU100Daily from "./sentiment/xu100";
import DailyNDX from "./sentiment/indices-NDX";
import Settings from "./Settings";
import DailyInstantSentiment from "./web/daily-instant-sentiment";
import DailySentiment from "./web/daily-sentiment";
import HourlySentiment from "./web/hourly-sentiment";
import Osilator from "./web/osilator";
import Senti1 from "./web/senti-1";
import Senti15 from "./web/senti-15";
import LinesDates from "./sentiment/lines-dates";
import BistTop30 from "./sentiment/bist-top-30";
import BistTop70 from "./sentiment/bist-top-70";
import BistPerformanceDaily from "./sentiment/bist-performance-daily";
import BistPerformanceWeekly from "./sentiment/bist-performance-weekly";
import BistPerformanceMonthly from "./sentiment/bist-performance-monthly";
import Worst30 from "./sentiment/worst-30";
import Worst70 from "./sentiment/worst-70";
import Worst500 from "./sentiment/worst-500";
import Messages from "./web/messages";
import WebUser from "./web/user";
import Lines500Snapshot from "./sentiment/lines-500-snapshot"
import Lines70Snapshot from "./sentiment/lines-70-snapshot"
import Lines200Snapshot from "./sentiment/lines-200-snapshot"
import Lines400Snapshot from "./sentiment/lines-400-snapshot"
import Lines30Snapshot from "./sentiment/lines-30-snapshot"
import Notifications from "./sentiment/notifications";
import Xu100MonthlyBalance from "./sentiment/xu100-monthly-balance";
import Senti1Snapshot from "./web/senti-1-snapshot";
import ShortTrade30 from "./sentiment/shortTrade-30";
import ShortTrade70 from "./sentiment/shortTrade-70";
import SentimentPower from "./sentiment/sentiment-power";
import FXLines30Snapshot from "./sentiment/fx-lines-30-snapshot";
import FXLines40Snapshot from "./sentiment/fx-lines-40-snapshot";
import FXLines50Snapshot from "./sentiment/fx-lines-50-snapshot";
import FXLines99Snapshot from "./sentiment/fx-lines-99-snapshot";
import FXSignal30 from "./sentiment/fx-signal-030";
import FXSignal40 from "./sentiment/fx-signal-040";
import FXSignal50 from "./sentiment/fx-signal-050";
import FXSignal99 from "./sentiment/fx-signal-099";
import FX030Balance from "./sentiment/fx-balance-30";
import FX040Balance from "./sentiment/fx-balance-40";
import FX050Balance from "./sentiment/fx-balance-50";
import FX099Balance from "./sentiment/fx-balance-99";
import FXTrade30 from "./sentiment/fx-trade-030";
import FXTrade40 from "./sentiment/fx-trade-040.";
import FXTrade50 from "./sentiment/fx-trade-050";
import FXTrade99 from "./sentiment/fx-trade-099";
import DailyBalances from "./sentiment/balances-daily";
import DailyBalancesExtended from "./sentiment/balances-daily-extended";
import Portfolio30 from "./sentiment/portfolio-30";
import TradeSP500 from "./sentiment/trade-1000";
import LinesSP500 from "./sentiment/lines-1000";
import SignalSP500 from "./sentiment/signal-1000";
import SP500DailyBalances from "./sentiment/balances-sp500";

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    DailySentiment,
    DailyInstantSentiment,
    HourlySentiment,
    Osilator,
    Senti1,
    Senti15,
    XU30Daily,
    XU100Daily,
    Xu100MonthlyBalance,
    Xsen030MonthlyBalance,
    Xsen070MonthlyBalance,
    Xsen500MonthlyBalance,
    Portfolio,
    News,
    DailySuggestions,
    TradeSP500,
    LinesSP500,
    SignalSP500,
    SP500DailyBalances,
    Lines30,
    Lines70,
    Lines200,
    Lines400,
    Lines500,
    Lines99,
    Signal30,
    Signal70,
    Signal200,
    Signal400,
    Signal500,
    Signal99,
    Xsen030DailyBalance,
    Xsen070DailyBalance,
    Xsen500DailyBalance,
    DailyNDX,
    Trade30,
    Trade70,
    Trade200,
    Trade400,
    Trade500,
    ShortTrade30,
    ShortTrade70,
    NsUser,
    LinesDates,
    BistTop30,
    BistTop70,
    BistPerformanceDaily,
    BistPerformanceWeekly,
    BistPerformanceMonthly,
    Worst30,
    Worst70,
    Worst500,
    Messages,
    WebUser,
    Lines30Snapshot,
    Lines70Snapshot,
    Lines200Snapshot,
    Lines400Snapshot,
    Lines500Snapshot,
    Notifications,
    Senti1Snapshot,
    SentimentPower,
    FXLines30Snapshot,
    FXLines40Snapshot,
    FXLines50Snapshot,
    FXLines99Snapshot,
    FXSignal30,
    FXSignal40,
    FXSignal50,
    FXSignal99,
    FX030Balance,
    FX040Balance,
    FX050Balance,
    FX099Balance,
    FXTrade30,
    FXTrade40,
    FXTrade50,
    FXTrade99,
    DailyBalances,
    DailyBalancesExtended,
    Portfolio30,


    NiftyLines20,
    NiftyLines30,
    NiftyLines50,
    NiftySignal20,
    NiftySignal30,
    NiftySignal50,
    NiftyTrade20,
    NiftyTrade30,
    NiftyTrade50,
    NiftyLinesDates
  });

export default createRootReducer;
