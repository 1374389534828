import { Button, Modal, Select, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSentimentPower,
  sentimentPowerSelector,
} from "../../../appRedux/reducers/sentiment/sentiment-power";
import { nsUserSelector } from "../../../appRedux/reducers/sentiment/user";
import {
  loadXu30Daily,
  xu30DailySelector,
} from "../../../appRedux/reducers/sentiment/xu030";
import {
  loadXu100Daily,
  xu100DailySelector,
} from "../../../appRedux/reducers/sentiment/xu100";
import {
  dailyInstantSentimentSelector,
  loadDailyInstantSentiment,
} from "../../../appRedux/reducers/web/daily-instant-sentiment";
import { SentimentPower } from "../../../components/SentimentPower";
import { firebaseApp } from "../../../firebase";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import chroma from "chroma-js";
import { THEME_TYPE_DARK } from "../../../constants/ThemeSetting";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import { SettingOutlined } from "@ant-design/icons";
import LanguageMenu from "../../../components/LanguageMenu/LanguageMenu";
import { isProd, isStaging } from "../../../util/Env";
import "./index.css";
import { getXndx070 } from "../../../routes/common/common";

const POWER_SCALE = ["#ff0000", "#ff9933", "#FDDD60", "#39d121"];
const Option = Select.Option;
export const Header = (props: any) => {
  const intl = useIntl();
  const sentimentPowerIndicator = intl.formatMessage({
    id: "header.sentiment_power_indicator",
  });

  const [showPower, setShowPower] = useState(false);
  const [lastPowerDate, setLastPowerDate] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { themeType } = useSelector(({ settings }: any) => settings);

  useEffect(() => {
    dispatch(loadXu100Daily(firebaseApp));
    dispatch(loadXu30Daily(firebaseApp));
    dispatch(loadDailyInstantSentiment(firebaseApp));
    dispatch(loadSentimentPower(firebaseApp));
  }, [nsUser]);

  const handleKlineIntervalChange = (interval: any) => {
    localStorage.setItem("globalklineInterval", interval);
    window.location.reload();
  };


  const getKlineColor = () => {
    var klineInterval = localStorage.getItem("globalklineInterval");
    if (klineInterval == "16H") return "gx-text2-info gx-border-white gx-bg-weekly";
    if (klineInterval == "4H") return " gx-text2-orange gx-border-orange gx-bg-daily";
    return "";
  };

  const setXndx070 = (val: any) => {
    var storage = localStorage.getItem("XNdx070");
    localStorage.setItem("XNdx070", val);
    window.location.reload();
  };



  return (
    <div className={"custom-header" + getKlineColor()} style={{ borderBottom: "1px solid #e8e8e8" }}>
       <div className="header-desktop-settings pull-right" >
        <LanguageMenu setShowsSettings={props.setShowsSettings} />
      </div>
      <div>
        <Select
          defaultValue={localStorage.getItem("globalklineInterval") ?? ""}
           className="gx-m-0 gx-p-0"
          style={{ width: 180, }}
          onChange={handleKlineIntervalChange}
        >
          <Option value="">  <IntlMessages id="header.interval_daily" /></Option>
          <Option value="4H">  <IntlMessages id="header.interval_4h" /></Option>
        </Select>

        <div className="multi-chart-switch gx-ml-3">
          <Switch
            size="small"
            defaultChecked={getXndx070()}
            checkedChildren={"XNdx070"}
            unCheckedChildren={"XNdx070"}
            onChange={(v) => setXndx070(v)}
          ></Switch>
        </div>
       
      </div>
    </div>
  );
};
