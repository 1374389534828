import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 10 * 500;
const COLLECTION_NAME = "NS_SP500_TRADES";

interface IXUData {
  tradeSP500: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  tradeSP500: [],
  requested: false,
  loaded: false,
};

const calcDuration = (open: any, close: any) => {
  //moment(close, "DD.MM.YYYY").diff(moment(open, "DD.MM.YYYY"), 'days');
  var startDate = moment(open, "DD.MM.YYYY").toDate();
  var endDate = moment(close, "DD.MM.YYYY").toDate();
  var nextDay = new Date(startDate);
  var cnt = 0;
  do {
    cnt += (nextDay.getDay() >= 1 && nextDay.getDay() <= 5) ? 1 : 0;
    nextDay.setDate(nextDay.getDate() + 1);
  } while (nextDay <= endDate);

  return cnt;
}

export function loadTradeSP500(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.TradeSP500.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.TRADE_SP500,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var tradeSP500Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var opendt = doc.data().openDate.split(".");
          tradeSP500Data.push({
            uid: doc.data().symbol + '_' + doc.data().openDate,
            name: "tradeSP500",
            Index: doc.data().index,
            DataPackage: doc.data().dataPackage,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            Symbol: doc.data().symbol,
            ODate: moment(doc.data().openDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm"),
            CDate: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm") : "",
            OPrice: doc.data().openPrice,
            CPrice: doc.data().closePrice,
            PerDay: doc.data().perDay,
            Percent: doc.data().percent,
            Duration: doc.data().closeDate != "" ? calcDuration(doc.data().openDate, doc.data().closeDate) : 0,
            dateTime: doc.data().dateTime.toDate(),
            dateOpen: new Date(opendt[2], opendt[1] - 1, opendt[0]),
            IncomingSymbol: doc.data().incomingSymbol,
            STP: doc.data().stpPositive
          });
        });


        dispatch({
          type: SENTIMENT_ACTION.TRADE_SP500,
          payload: {
            tradeSP500: tradeSP500Data.sort((a: any, b: any) => { return b.dateTime - a.dateTime || a.dateOpen - b.dateOpen }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function TradeSP500(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TRADE_SP500:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const tradeSP500Selector = (state: any) => state.TradeSP500;
