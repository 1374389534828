import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import UserProfile from "./UserProfile";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();

  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();
  const nsUser = useSelector(nsUserSelector);


  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);
  const notifications = useSelector(notificationSelector);
  const unreadNotifications = notifications && notifications?.data ? notifications?.data?.filter(n => !n.read).length : 0;
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }


  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="main">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span>  <IntlMessages id="sidebar.home" /></span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item key="index">
              <Link to="/index/home">
                
                <span> <i className="icon icon-chart-line" />Endeks</span>
              </Link>
            </Menu.Item> */}
            {nsUser?.package?.globalAccess &&
              <Menu.SubMenu key="nasdaq" 
              popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <><i className="icon icon-chart-area" />
                <span> US Markets</span>
                </>
                }>
                <Menu.Item key="nasdaq/lines">
                  <Link to="/nasdaq/lines"><i className="icon icon-widgets" /><span> <IntlMessages id="sidebar.bist.symbols" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nasdaq/explore">
                  <Link to="/nasdaq/explore"><i className="icon icon-search-new" /><span><IntlMessages id="sidebar.bist.explorer" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nasdaq/follower">
                  <Link to="/nasdaq/follower"><i className="icon icon-filter" /><span><IntlMessages id="sidebar.bist.follower" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nasdaq/signals">
                  <Link to="/nasdaq/signals"><i className="icon icon-megaphone" /><span> <IntlMessages id="sidebar.bist.first_list" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nasdaq/trades">
                  <Link to="/nasdaq/trades"><i className="icon icon-pricing-table" /><span><IntlMessages id="sidebar.bist.first_list_history" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nasdaq/balances">
                  <Link to="/nasdaq/balances"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.profit_charts" /></span></Link>
                </Menu.Item>
                {/* <Menu.Item key="nasdaq/portfolio">
                  <Link to="/nasdaq/portfolio"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.portfolio" /></span></Link>
                </Menu.Item> */}
              </Menu.SubMenu>
            }


            {
              nsUser?.package?.fx &&
              <Menu.SubMenu key="fx" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <><i className="icon icon-chart-area" />
                <span> FX</span></>}>
                <Menu.Item key="fx/lines">
                  <Link to="/fx/lines"><i className="icon icon-widgets" /><span><IntlMessages id="sidebar.bist.symbols" /></span></Link>
                </Menu.Item>
                <Menu.Item key="fx/history">
                  <Link to="/fx/history"><i className="icon icon-widgets" /><span><IntlMessages id="sidebar.bist.symbolsHistory" /></span></Link>
                </Menu.Item>
                <Menu.Item key="fx/explore">
                  <Link to="/fx/explore"><i className="icon icon-search-new" /><span><IntlMessages id="sidebar.bist.explorer" /></span></Link>
                </Menu.Item>
                <Menu.Item key="fx/signals">
                  <Link to="/fx/signals"><i className="icon icon-megaphone" /><span><IntlMessages id="sidebar.bist.first_list" /></span></Link>
                </Menu.Item>
                <Menu.Item key="fx/trades">
                  <Link to="/fx/trades"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.first_list_history" /></span></Link>
                </Menu.Item>
              </Menu.SubMenu>
            }

            {
              nsUser?.package?.admin &&
              <Menu.SubMenu key="sp500" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <> 
                  <i className="icon icon-chart-area" />
                  <span>        
                  Senti-SPX
                  </span>
                  </>
               }>
                <Menu.Item key="sp500/lines">
                  <Link to="/sp500/lines"><i className="icon icon-widgets" /><span> <IntlMessages id="sidebar.bist.symbols" /></span></Link>
                </Menu.Item>
                <Menu.Item key="sp500/signals">
                  <Link to="/sp500/signals"><i className="icon icon-megaphone" /><span> <IntlMessages id="sidebar.bist.first_list" /></span></Link>
                </Menu.Item>
                <Menu.Item key="sp500/trades">
                  <Link to="/sp500/trades"><i className="icon icon-pricing-table" /><span><IntlMessages id="sidebar.bist.first_list_history" /></span></Link>
                </Menu.Item>
                <Menu.Item key="sp500/balances">
                  <Link to="/sp500/balances"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.profit_charts" /></span></Link>
                </Menu.Item>
                <Menu.Item key="sp500/explore">
                  <Link to="/sp500/explore"><i className="icon icon-search-new" /><span><IntlMessages id="sidebar.bist.explorer" /></span></Link>
                </Menu.Item>
              </Menu.SubMenu>
            }

            {nsUser?.package?.admin &&
              <Menu.SubMenu key="nifty50" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span> <i className="icon icon-chart-area" />Nifty50</span>}>
                <Menu.Item key="nifty50/lines">
                  <Link to="/nifty50/lines"><i className="icon icon-widgets" /><span> <IntlMessages id="sidebar.bist.symbols" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nifty50/signals">
                  <Link to="/nifty50/signals"><i className="icon icon-megaphone" /><span> <IntlMessages id="sidebar.bist.first_list" /></span></Link>
                </Menu.Item>
                <Menu.Item key="nifty50/trades">
                  <Link to="/nifty50/trades"><i className="icon icon-pricing-table" /><span><IntlMessages id="sidebar.bist.first_list_history" /></span></Link>
                </Menu.Item>
                {/* <Menu.Item key="nifty50/balances">
                  <Link to="/nifty50/balances"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.profit_charts" /></span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="nasdaq/portfolio">
                  <Link to="/nasdaq/portfolio"><i className="icon icon-dasbhoard" /><span><IntlMessages id="sidebar.bist.portfolio" /></span></Link>
                </Menu.Item> */}
              </Menu.SubMenu>
            }

            <Menu.Item key="exit">
              <Link onClick={onLogoutClick}>
                <i className="icon icon-close-circle" />
                <span> <IntlMessages id="sidebar.logout" /></span>
              </Link>
            </Menu.Item>

          </Menu>
         
        </CustomScrollbars>
        <UserProfile/>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

