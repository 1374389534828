const sectorAndIndustries = [
    {
        Symbol: "GE",
        Company: "GE Aerospace",
        Sector: "Aerospace & Defense",
        Industry: "Industrials"
    },
    {
        Symbol: "LMT",
        Company: "Lockheed Martin ",
        Sector: "Aerospace & Defense",
        Industry: "Industrials"
    },
    {
        Symbol: "RTX",
        Company: "RTX Corp",
        Sector: "Aerospace & Defense",
        Industry: "Industrials"
    },
    {
        Symbol: "BA",
        Company: "The Boeing Company",
        Sector: "Aerospace & Defense",
        Industry: "Industrials"
    },
    {
        Symbol: "TJX",
        Company: "The TJX Companies",
        Sector: "Apparel Retail",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "BX",
        Company: "Blackstone ",
        Sector: "Asset Management",
        Industry: "Financials"
    },
    {
        Symbol: "F",
        Company: "Ford Motor Company",
        Sector: "Auto Manufacturers",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "BAC",
        Company: "Bank of America ",
        Sector: "Banks - Diversified",
        Industry: "Financials"
    },
    {
        Symbol: "C",
        Company: "Citigroup ",
        Sector: "Banks - Diversified",
        Industry: "Financials"
    },
    {
        Symbol: "JPM",
        Company: "JPMorgan Chase & Co.",
        Sector: "Banks - Diversified",
        Industry: "Financials"
    },
    {
        Symbol: "WFC",
        Company: "Wells Fargo & Company",
        Sector: "Banks - Diversified",
        Industry: "Financials"
    },
    {
        Symbol: "KO",
        Company: "The Coca-Cola Company",
        Sector: "Beverages - Non-Alcoholic",
        Industry: "Consumer Staples"
    },
    {
        Symbol: "MS",
        Company: "Morgan Stanley",
        Sector: "Capital Markets",
        Industry: "Financials"
    },
    {
        Symbol: "GOOGL",
        Company: "Alphabet ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "GOOG",
        Company: "Alphabet ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "CHTR",
        Company: "Charter Communications",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "CMCSA",
        Company: "Comcast ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "EA",
        Company: "Electronic Arts ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "META",
        Company: "Meta Platforms",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "NFLX",
        Company: "Netflix",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "SIRI",
        Company: "Sirius XM Holdings ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "TMUS",
        Company: "T-Mobile US",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "WBD",
        Company: "Warner Bros. Discovery",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "FVRR",
        Company: "Fiverr International ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "PINS",
        Company: "Pinterest",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "SNAP",
        Company: "Snap ",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "SPOT",
        Company: "Spotify Technology S.A.",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "TKC",
        Company: "Turkcell Iletisim Hizmetleri A.S.",
        Sector: "Communication Services",
        Industry: "Internet Content & Information"
    },
    {
        Symbol: "ANET",
        Company: "Arista Networks",
        Sector: "Computer Hardware",
        Industry: "Technology"
    },
    {
        Symbol: "SMCI",
        Company: "Super Micro Computer",
        Sector: "Computer Hardware",
        Industry: "Technology"
    },
    {
        Symbol: "ABNB",
        Company: "Airbnb",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "AMZN",
        Company: "Amazon.com",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "BKNG",
        Company: "Booking Holdings ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "LULU",
        Company: "Lululemon Athletica ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "MAR",
        Company: "Marriott International",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "MELI",
        Company: "MercadoLibre",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "ORLY",
        Company: "O'Reilly Automotive",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "PDD",
        Company: "PDD Holdings ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "ROST",
        Company: "Ross Stores",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "SBUX",
        Company: "Starbucks ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "TSLA",
        Company: "Tesla",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "BABA",
        Company: "Alibaba Group Holding",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "CHWY",
        Company: "Chewy",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "RACE",
        Company: "Ferrari N.V.",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "QS",
        Company: "QuantumScape ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "STLA",
        Company: "Stellantis N.V.",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "TM",
        Company: "Toyota Motor ",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "WH",
        Company: "Wyndham Hotels & Resorts",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "HEPS",
        Company: "D-Market Electronics",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "JD",
        Company: "JD.com",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "LCID",
        Company: "Lucid Group",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "MGIH",
        Company: "Millennium Group International Holdings",
        Sector: "Consumer Discretionary",
        Industry: "Packaging & Containers"
    },
    {
        Symbol: "RIVN",
        Company: "F6vian Automotive",
        Sector: "Consumer Discretionary",
        Industry: "Internet Retail"
    },
    {
        Symbol: "COST",
        Company: "Costco Wholesale ",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "DLTR",
        Company: "Dollar Tree",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "KDP",
        Company: "Keurig Dr Pepper ",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "MDLZ",
        Company: "Mondelez International",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "MNST",
        Company: "Monster Beverage ",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "PEP",
        Company: "PepsiCo",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "KHC",
        Company: "The Kraft Heinz Company",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "BUD",
        Company: "Anheuser-Busch InBev SA/NV",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "BTI",
        Company: "British American Tobacco",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "FMX",
        Company: "Fomento Económico Mexicano",
        Sector: "Consumer Staples",
        Industry: "Discount Stores"
    },
    {
        Symbol: "AXP",
        Company: "American Express Company",
        Sector: "Credit Services",
        Industry: "Financials"
    },
    {
        Symbol: "MA",
        Company: "Mastercard",
        Sector: "Credit Services",
        Industry: "Financials"
    },
    {
        Symbol: "V",
        Company: "Visa ",
        Sector: "Credit Services",
        Industry: "Financials"
    },
    {
        Symbol: "DHR",
        Company: "Danaher ",
        Sector: "Diagnostics & Research",
        Industry: "Healthcare"
    },
    {
        Symbol: "TMO",
        Company: "Thermo Fisher Scientific ",
        Sector: "Diagnostics & Research",
        Industry: "Healthcare"
    },
    {
        Symbol: "WMT",
        Company: "Walmart ",
        Sector: "Discount Stores",
        Industry: "Consumer Staples"
    },
    {
        Symbol: "ABBV",
        Company: "AbbVie ",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "BMY",
        Company: "Bristol-Myers Squibb Company",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "LLY",
        Company: "Eli Lilly and Company",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "JNJ",
        Company: "Johnson & Johnson",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "MRK",
        Company: "Merck & Co.",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "PFE",
        Company: "Pfizer ",
        Sector: "Drug Manufacturers",
        Industry: "Healthcare"
    },
    {
        Symbol: "BKR",
        Company: "Baker Hughes Company",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "FANG",
        Company: "Diamondback Energy",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "BP",
        Company: "BP",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "DINO",
        Company: "HF Sinclair ",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "PBR",
        Company: "Petróleo Brasileiro S.A.",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "SHEL",
        Company: "Shell plc",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "TTE",
        Company: "TotalEnergies SE",
        Sector: "Energy",
        Industry: "Oil & Gas Exploration"
    },
    {
        Symbol: "PARA",
        Company: "Paramount Global",
        Sector: "Entertainment",
        Industry: "Communication Services"
    },
    {
        Symbol: "DIS",
        Company: "The Walt Disney Company",
        Sector: "Entertainment",
        Industry: "Communication Services"
    },
    {
        Symbol: "CAT",
        Company: "Caterpillar ",
        Sector: "Farm & Heavy Construction Machinery",
        Industry: "Industrials"
    },
    {
        Symbol: "DE",
        Company: "Deere & Company",
        Sector: "Farm & Heavy Construction Machinery",
        Industry: "Industrials"
    },
    {
        Symbol: "NDAQ",
        Company: "Nasdaq",
        Sector: "Financial Data & Stock Exchanges",
        Industry: "Financials"
    },
    {
        Symbol: "PYPL",
        Company: "PayPal Holdings",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "HSBC",
        Company: "HSBC Holdings plc",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "IBN",
        Company: "ICICI Bank",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "LYG",
        Company: "Lloyds Banking Group plc",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "NYCB",
        Company: "New York Community Bancorp",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "RLI",
        Company: "RLI Corp.",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "TD",
        Company: "The Toronto-Dominion Bank",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "UBS",
        Company: "UBS Group AG",
        Sector: "Financials",
        Industry: "Credit Services"
    },
    {
        Symbol: "NKE",
        Company: "NIKE",
        Sector: "Footwear & Accessories",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "AMGN",
        Company: "Amgen ",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "AZN",
        Company: "AstraZeneca PLC",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "BIIB",
        Company: "Biogen ",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "DXCM",
        Company: "DexCom",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "GILD",
        Company: "Gilead Sciences",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "IDXX",
        Company: "IDEXX Laboratories",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "ILMN",
        Company: "Illumina",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "ISRG",
        Company: "Intuitive Surgical",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "MRNA",
        Company: "Moderna",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "REGN",
        Company: "Regeneron Pharmaceuticals",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "VRTX",
        Company: "Vertex Pharmaceuticals",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "WBA",
        Company: "Walgreens Boots Alliance",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "CHE",
        Company: "Chemed ",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "DNA",
        Company: "Ginkgo Bioworks Holdings",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "NVS",
        Company: "Novartis AG",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "NVO",
        Company: "Novo Nordisk A/S",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "BMRN",
        Company: "EtioMaiin Pharmaceutical ",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "NNOX",
        Company: "Nano-X Imaging ",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "OABI",
        Company: "OmniAb",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "RXRX",
        Company: "Recursion Pharmaceuticals",
        Sector: "Healthcare",
        Industry: "Biotechnology"
    },
    {
        Symbol: "SNY",
        Company: "Sanof",
        Sector: "Healthcare",
        Industry: "Drug Manufacturers"
    },
    {
        Symbol: "CI",
        Company: "Cigna ",
        Sector: "Healthcare Plans",
        Industry: "Healthcare"
    },
    {
        Symbol: "CVS",
        Company: "CVS Health ",
        Sector: "Healthcare Plans",
        Industry: "Healthcare"
    },
    {
        Symbol: "ELV",
        Company: "Elevance Health ",
        Sector: "Healthcare Plans",
        Industry: "Healthcare"
    },
    {
        Symbol: "UNH",
        Company: "UnitedHealth Group",
        Sector: "Healthcare Plans",
        Industry: "Healthcare"
    },
    {
        Symbol: "LOW",
        Company: "Lowe's Companies",
        Sector: "Home Improvement Retail",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "HD",
        Company: "The Home Depot",
        Sector: "Home Improvement Retail",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "PG",
        Company: "The Procter & Gamble Company",
        Sector: "Household & Personal Products",
        Industry: "Consumer Staples"
    },
    {
        Symbol: "ADP",
        Company: "Automatic Data Processing",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "CTAS",
        Company: "Cintas ",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "CPRT",
        Company: "Copart",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "CSX",
        Company: "CSX ",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "FAST",
        Company: "Fastenal Company",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "HON",
        Company: "Honeywell International ",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "ODFL",
        Company: "Old Dominion Freight Line",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "PCAR",
        Company: "PACCAR Inc",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "PAYX",
        Company: "Paychex",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "VRSK",
        Company: "Verisk Analytics",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "AL",
        Company: "Air Lease ",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "CNI",
        Company: "Canadian National Railway",
        Sector: "Industrials",
        Industry: "Conglomerates"
    },
    {
        Symbol: "ACN",
        Company: "Accenture plc",
        Sector: "Information Technology Services",
        Industry: "Technology"
    },
    {
        Symbol: "FI",
        Company: "Fiserv",
        Sector: "Information Technology Services",
        Industry: "Technology"
    },
    {
        Symbol: "IBM",
        Company: "International Business Machines ",
        Sector: "Information Technology Services",
        Industry: "Technology"
    },
    {
        Symbol: "CB",
        Company: "Chubb",
        Sector: "Insurance - Property & Casualty",
        Industry: "Financials"
    },
    {
        Symbol: "PGR",
        Company: "The Progressive ",
        Sector: "Insurance - Property & Casualty",
        Industry: "Financials"
    },
    {
        Symbol: "MMC",
        Company: "Marsh & McLennan Companies",
        Sector: "Insurance Brokers",
        Industry: "Financials"
    },
    {
        Symbol: "FDX",
        Company: "FedEx ",
        Sector: "Integrated Freight & Logistics",
        Industry: "Industrials"
    },
    {
        Symbol: "EBAY",
        Company: "eBay ",
        Sector: "Internet Retail",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "OR",
        Company: "Osisko Gold Royalties Ltd",
        Sector: "Materials",
        Industry: "Other Industrial Metals & Mining"
    },
    {
        Symbol: "RIO",
        Company: "Rio Tinto Group",
        Sector: "Materials",
        Industry: "Other Industrial Metals & Mining"
    },
    {
        Symbol: "HCA",
        Company: "HCA Healthcare",
        Sector: "Medical Care Facilities",
        Industry: "Healthcare"
    },
    {
        Symbol: "ABT",
        Company: "Abbott Laboratories",
        Sector: "Medical Devices",
        Industry: "Healthcare"
    },
    {
        Symbol: "ALGN",
        Company: "Align Technology",
        Sector: "Medical Devices",
        Industry: "Healthcare"
    },
    {
        Symbol: "BSX",
        Company: "Boston Scientific ",
        Sector: "Medical Devices",
        Industry: "Healthcare"
    },
    {
        Symbol: "MDT",
        Company: "Medtronic plc",
        Sector: "Medical Devices",
        Industry: "Healthcare"
    },
    {
        Symbol: "COP",
        Company: "Conoco Phillips",
        Sector: "Oil & Gas Exploration",
        Industry: "Energy"
    },
    {
        Symbol: "MRO",
        Company: "Marathon Oil ",
        Sector: "Oil & Gas Exploration",
        Industry: "Energy"
    },
    {
        Symbol: "CVX",
        Company: "Chevron ",
        Sector: "Oil & Gas Integrated",
        Industry: "Energy"
    },
    {
        Symbol: "XOM",
        Company: "Exxon Mobil ",
        Sector: "Oil & Gas Integrated",
        Industry: "Energy"
    },
    {
        Symbol: "PSX",
        Company: "Phillips 66",
        Sector: "Oil & Gas Refining & Marketing",
        Industry: "Energy"
    },
    {
        Symbol: "VLO",
        Company: "Valero Energy ",
        Sector: "Oil & Gas Refining & Marketing",
        Industry: "Energy"
    },
    {
        Symbol: "UNP",
        Company: "Union Pacific ",
        Sector: "Railroads",
        Industry: "Industrials"
    },
    {
        Symbol: "CSGP",
        Company: "CoStar Group",
        Sector: "Real Estate",
        Industry: "Real Estate Services"
    },
    {
        Symbol: "CBRE",
        Company: "CBRE Group",
        Sector: "Real Estate Services",
        Industry: "Real Estate"
    },
    {
        Symbol: "PLD",
        Company: "Prologis",
        Sector: "REIT - Industrial",
        Industry: "Real Estate"
    },
    {
        Symbol: "AMT",
        Company: "American Tower ",
        Sector: "REIT - Specialty",
        Industry: "Real Estate"
    },
    {
        Symbol: "CCI",
        Company: "Crown Castle ",
        Sector: "REIT - Specialty",
        Industry: "Real Estate"
    },
    {
        Symbol: "MCD",
        Company: "McDonald's ",
        Sector: "Restaurants",
        Industry: "Consumer Discretionary"
    },
    {
        Symbol: "TER",
        Company: "Teradyne",
        Sector: "Semiconductor Equipment & Materials",
        Industry: "Technology"
    },
    {
        Symbol: "QRVO",
        Company: "Qorvo",
        Sector: "Semiconductors",
        Industry: "Technology"
    },
    {
        Symbol: "CRM",
        Company: "Salesforce",
        Sector: "Application",
        Industry: "Technology"
    },
    {
        Symbol: "NOW",
        Company: "ServiceNow",
        Sector: "Application",
        Industry: "Technology"
    },
    {
        Symbol: "UBER",
        Company: "Uber Technologies",
        Sector: "Application",
        Industry: "Technology"
    },
    {
        Symbol: "ORCL",
        Company: "Oracle ",
        Sector: "Infrastructure",
        Industry: "Technology"
    },
    {
        Symbol: "ENPH",
        Company: "Enphase Energy",
        Sector: "Solar",
        Industry: "Technology"
    },
    {
        Symbol: "LIN",
        Company: "Linde plc",
        Sector: "Specialty Chemicals",
        Industry: "Materials"
    },
    {
        Symbol: "ADBE",
        Company: "Adobe ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "AMD",
        Company: "Advanced Micro Devices",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "ADI",
        Company: "Analog Devices",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "ANSS",
        Company: "ANSYS",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "AAPL",
        Company: "Apple Inc",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "AMAT",
        Company: "Applied Materials",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "ASML",
        Company: "ASML Holding N.V.",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "TEAM",
        Company: "Atlassian ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "ADSK",
        Company: "Autodesk",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "AVGO",
        Company: "Broadcom ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CDNS",
        Company: "Cadence Design Systems",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CSCO",
        Company: "Cisco Systems",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CTSH",
        Company: "Cognizant Technology Solutions ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CRWD",
        Company: "CrowdStrike Holdings",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "DDOG",
        Company: "Datadog",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "FTNT",
        Company: "Fortinet",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "GFS",
        Company: "GLOBALFOUNDRIES ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "INTC",
        Company: "Intel ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "INTU",
        Company: "Intuit ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "KLAC",
        Company: "KLA ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "LRCX",
        Company: "Lam Research ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "MRVL",
        Company: "Marvell Technology",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "MCHP",
        Company: "Microchip Technology",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "MU",
        Company: "Micron Technology",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "MSFT",
        Company: "Microsoft ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "NVDA",
        Company: "Nvidia",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "NXPI",
        Company: "NXP Semiconductors N.V.",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "PANW",
        Company: "Palo Alto Networks",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "QCOM",
        Company: "Qualcomm",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SNPS",
        Company: "Synopsys",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "TXN",
        Company: "Texas Instruments",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "WDAY",
        Company: "Workday",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "BBAI",
        Company: "BigBear.ai Holdings",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "GIB",
        Company: "CGI ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CIEN",
        Company: "Ciena ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "DELL",
        Company: "Dell Technologies ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "PLTR",
        Company: "Palantir Technologies ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SAP",
        Company: "SAP SE",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SHOP",
        Company: "Shopify ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SNOW",
        Company: "Snowflake ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SONY",
        Company: "Sony Group ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "TSM",
        Company: "Taiwan Semiconductor Manufacturing",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "TIXT",
        Company: "TELUS International",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "PATH",
        Company: "UiPath ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "APP",
        Company: "Applovin ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "ARM",
        Company: "Am, Holdings plc",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "BMR",
        Company: "Beamr Imaging ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "CRUS",
        Company: "Cirrus Logic ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "DCBO",
        Company: "Docebo",
        Sector: "Technology",
        Industry: "Application"
    },
    {
        Symbol: "FLEX",
        Company: "Flex ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "OKTA",
        Company: "Okta",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "OTEX",
        Company: "Open Text ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "RMBS",
        Company: "Rambus ",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "SOUN",
        Company: "SoundHound Al",
        Sector: "Technology",
        Industry: "Infrastructure"
    },
    {
        Symbol: "T",
        Company: "AT&T ",
        Sector: "Telecom Services",
        Industry: "Communication Services"
    },
    {
        Symbol: "VZ",
        Company: "Verizon Communications ",
        Sector: "Telecom Services",
        Industry: "Communication Services"
    },
    {
        Symbol: "AEP",
        Company: "American Electric Power Company",
        Sector: "Utilities",
        Industry: "Renewable"
    },
    {
        Symbol: "EXC",
        Company: "Exelon ",
        Sector: "Utilities",
        Industry: "Renewable"
    },
    {
        Symbol: "XEL",
        Company: "Xcel Energy ",
        Sector: "Utilities",
        Industry: "Renewable"
    },
    {
        Symbol: "DUK",
        Company: "Duke Energy ",
        Sector: "Utilities",
        Industry: "Utilities"
    },
    {
        Symbol: "TBB",
        Company: "AT&T Inc. 5.35%",
        Sector: "Telecom Services",
        Industry: "Communication Services"
    },
    {
        Symbol: "TBC",
        Company: "AT&T Inc. 5.625%",
        Sector: "Telecom Services",
        Industry: "Communication Services"
    }
]

export default sectorAndIndustries;